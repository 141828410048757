const EventSection = ({ title, elements, loading, dummyColumns }) => {
  const columnItems = Object.keys(elements).map((column) => {
    column = column.replaceAll("_", " ");
    return <th key={column}>{column}</th>;
  });
  const valuesItems = Object.values(elements).map((val, i) => (
    <td key={i}>{val ? val : " - "}</td>
  ));

  const skeletonColumnItems = [];
  const skeletonValuesItems = [];
  for(let i =0; i < dummyColumns; i++){
    skeletonColumnItems.push(<th key={i}><div className="skeleton skeleton-text w-75"/></th>);
    skeletonValuesItems.push(<td key={i}><div className="skeleton skeleton-text w-100"/></td>);
  }
  return (
    <section className="card mb-2 overflow-auto">
      <div className="card-body">
        <table className="table">
          <thead className="bb-secondary w-100 bg-tertiary">
            <tr>
              <th
                className="rounded-top fs-5"
                colSpan={loading ? dummyColumns : Object.keys(elements).length}
              >
                {loading ? <div className="skeleton skeleton-text w-25 py-2"/> : title}
              </th>
            </tr>
          </thead>
          <tbody className="">
            <tr className="text-capitalize">{loading ? skeletonColumnItems : columnItems}</tr>
            <tr>{loading ? skeletonValuesItems :  valuesItems}</tr>
          </tbody>
        </table>
      </div>
    </section>
  );
};

export default EventSection;
