import { Nav, Navbar } from "react-bootstrap";
import { useLocation } from "react-router";
import CustomLink from "./CustomLink";

//import empres_logo from '../images/empress-i_plus_logo.svg';
import empres_logo from "../images/EMPRES_i_typographic_logo.svg";
import fao_logo from "../images/FAO_logo_White_2lines_en.svg";

import overview_dark from "../images/overview_dark.svg";
import overview_light from "../images/overview_light.svg";
import diseases_dark from "../images/diseases_dark.svg";
import diseases_light from "../images/diseases_light.svg";
import epi_dark from "../images/epi_dark.svg";
import epi_light from "../images/epi_light.svg";
import laboratories_dark from "../images/laboratories_dark.svg";
import laboratories_light from "../images/laboratories_light.svg";

const Header = () => {
  const location = useLocation();

  return (
    <div className="header">
      <Navbar
        bg="headerBlue"
        variant="dark"
        expand="lg"
        bs-class="empres-header"
        className="py-3"
      >
        <Navbar.Brand>
          <img src={fao_logo} className="headerLogo" alt="FAO" />
        </Navbar.Brand>
        <Navbar.Brand className="ms-auto">
          <img src={empres_logo} className="headerLogo" alt="EMPRES-i " />
        </Navbar.Brand>
      </Navbar>
      {!location.pathname.startsWith("/event-report") && (
        <Navbar
          bg="headerBlue"
          variant="dark"
          expand="lg"
          bs-class="empres-header"
          className="d-flex justify-content-end pt-3 py-lg-0"
        >
          <Navbar.Toggle aria-controls="basic-navbar-nav" className="mb-3" />
          <Navbar.Collapse id="basic-navbar-nav" className="mb-3 mb-lg-0">
            <Nav variant="tabs" defaultActiveKey="/">
              <Nav.Item>
                <CustomLink
                  to="/"
                  iconDark={overview_dark}
                  iconLight={overview_light}
                  text="Overview"
                />
              </Nav.Item>
              <Nav.Item>
                <CustomLink
                  to="/diseases"
                  iconDark={diseases_dark}
                  iconLight={diseases_light}
                  text="Diseases"
                />
              </Nav.Item>
              <Nav.Item>
                <CustomLink
                  to="/epidemiology"
                  iconDark={epi_dark}
                  iconLight={epi_light}
                  text="Epidemiology"
                />
              </Nav.Item>
              <Nav.Item>
                <CustomLink
                  to="/laboratories"
                  iconDark={laboratories_dark}
                  iconLight={laboratories_light}
                  text="Laboratories"
                />
              </Nav.Item>
              <Nav.Item>
                <CustomLink to="/event-management" text="Event Management" />
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      )}
    </div>
  );
};

export default Header;
