import "bootstrap/dist/css/bootstrap.css";
import "./utilities.css";
import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";
import React, { useEffect } from 'react';
import ReactGA from "react-ga";
import Header from "./components/Header";
import AppContent from "./components/AppContent";

function App() {
  const setGA = () => {
    ReactGA.initialize([
      { trackingId: "UA-16796074-1" },
      { trackingId: "UA-16957552-1" },
      { trackingId: "UA-5482767-1" },
    ]);
    ReactGA.pageview(window.location.pathname + window.location.search);
  };

  useEffect(() => {
    setGA();
  }, []);

  return (
    <Router>
      <div className="App" id="main-div">
        <Header />
        <div className="app-container">
          <AppContent />
        </div>
      </div>
    </Router>
  );
}
//{key === "/directory" ?
//<iframe title="dvo_directory_report" className="embeded_gds_report" width="1366" height="1200" src="https://datastudio.google.com/embed/reporting/38d0e466-a8be-4bdc-86cc-70cb9ea2defa/page/FE21B" frameborder="0" allowfullscreen></iframe>

export default App;
