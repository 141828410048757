const EventArraySection = ({ title, elements, loading, dummyColumns }) => {
  const columnItems = Object.keys(elements[0]).map((column) => {
    column = column.replaceAll("_", " ");
    return <th key={column}>{column}</th>;
  });

  const valuesRows = [];
  elements.forEach((objEl) => {
    const row = Object.values(objEl).map((val, i) => (
      <td key={i}>{val ? val : " - "}</td>
    ));
    valuesRows.push(row);
  });

  const skeletonColumnItems = [];
  const skeletonValuesItems = [];
  for(let i =0; i < dummyColumns; i++){
    skeletonColumnItems.push(<th key={i}><div className="skeleton skeleton-text w-75"/></th>);
    skeletonValuesItems.push(<td key={i}><div className="skeleton skeleton-text w-100"/></td>);
  }
  return (
    <section className="card mb-2 overflow-auto">
      <div className="card-body">
        <table className="table">
          <thead className="bb-secondary">
            <tr>
              <th
                className="rounded-top bg-tertiary fs-5 w-100"
                colSpan={loading ? dummyColumns : Object.keys(elements[0]).length}
              >
                {loading ? (
                  <div className="skeleton skeleton-text w-25 py-2" />
                ) : (
                  title
                )}
              </th>
            </tr>
          </thead>
          <tbody className="">
            <tr className="text-capitalize">{loading ? skeletonColumnItems : columnItems}</tr>
            {loading ? <tr>{skeletonValuesItems}</tr> : valuesRows.map((row, i) => {
              return <tr key={i}>{row}</tr>;
            })}
          </tbody>
        </table>
      </div>
    </section>
  );
};

export default EventArraySection;
