import IframeResizer from "iframe-resizer-react";

const Events = () => {
  return (
    <div className="frame-container">
      <IframeResizer
        className="iframe-hand-in-hand frame"
        scrolling="true"
        log
        src="https://fao-empresi-private.web.app/sign_in"
        sizeHeight="true"
        sizeWidth="true"
        heightCalculationMethod="max"
      />
    </div>
  );
};

export default Events;
