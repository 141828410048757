import EventSection from "./EventSection";
import EventArraySection from "./EventArraySection";
import LeafMap from "./LeafMap";

/* import empres_logo from "../../images/empress-i_plus_logo.svg";*/
import download from "../../images/download.svg";

const EventReportContent = ({ donwloadAsPdf, mapCenter, eventReport, loading }) => {
  return (
    <main id="divToPrint" className="container-lg my-3">
      <header className="container-lg">
        <div className="d-flex align-items-center bg-tertiary rounded px-3">
          <h1 className="report-header my-3">Disease Event Details</h1>
          {/* &#8226; <img src={empres_logo} className="headerLogo" alt="EMPRES-i " /> */}
          <button
            type="button"
            className="btn btn-secondary ms-auto py-2"
            onClick={donwloadAsPdf}
            disabled={loading}
          >
            <img src={download} className="icon-download" alt="download" />
          </button>
        </div>
      </header>
      <article className="container-lg mt-2">
        <div className="row">
          <div className="col-lg">
            <EventSection
              title="General Info"
              elements={eventReport.generalInfo}
              loading={loading}
              dummyColumns={3}
            />
            <EventSection title="Disease" elements={eventReport.disease} loading={loading} dummyColumns={4}/>
          </div>
          <div className="col-lg-4 mb-2">
            <section className="card h-100">
              <div className="card-body">
              <LeafMap center={mapCenter} zoom={6} loading={loading}/>
              </div>
            </section>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <EventSection title="Location" elements={eventReport.location} loading={loading} dummyColumns={7}/>
          </div>
        </div>
        {eventReport.species.length > 0 && (
          <div className="row">
            <div className="col">
              <EventArraySection
                title="Species Affected"
                elements={eventReport.species}
                loading={loading}
                dummyColumns={8}
              />
            </div>
          </div>
        )}
        {eventReport.laboratories.length > 0 && (
          <div className="row">
            <div className="col">
              <EventArraySection
                title="Laboratory Tests"
                elements={eventReport.laboratories}
                loading={loading}
                dummyColumns={4}
              />
            </div>
          </div>
        )}
        {eventReport.humans_cases &&
          Object.keys(eventReport.humans_cases).length > 0 && (
            <div className="row">
              <div className="col">
                <EventSection
                  title="Human cases"
                  elements={eventReport.humans_cases}
                  loading={loading}
                  dummyColumns={3}
                />
              </div>
            </div>
          )}
      </article>
    </main>
  );
};

export default EventReportContent;
