const Laboratories = () => {
  return (
    <div className="container">
      <iframe
        title="labs_report"
        className="embeded_gds_report"
        width="1366"
        height="1200"
        src="https://datastudio.google.com/embed/reporting/ad2a4c8d-aa1b-4945-80f8-1f9ed2cf9756/page/FE21B"
        frameborder="0"
        allowfullscreen
      ></iframe>
    </div>
  );
};

export default Laboratories;
