import { useParams } from "react-router";
import { useEffect, useState } from "react";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { fetchDiseaseInfoById } from "../../lib/api";
import EventReportContent from "./EventReportContent";

const eventReportTemplate = {
  location: {},
  disease: {},
  generalInfo: {},
  species: [],
  laboratories: [],
  human_cases: {},
};

const EventReport = () => {
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [fetchError, setFetchError] = useState("");
  const [mapCenter, setCenter] = useState([0, 0]);
  const [eventReport, setEventReport] = useState(eventReportTemplate);

  useEffect(() => {
    const getDiseaseInfo = async () => {
      try {
        setLoading(true);
        const diseaseFromServer = await fetchDiseaseInfoById(id);
        setEventReport(diseaseFromServer);

        if (diseaseFromServer && Object.keys(diseaseFromServer).length > 0) {
          setFetchError("");
          setCenter([
            parseFloat(diseaseFromServer.location.lat),
            parseFloat(diseaseFromServer.location.lng),
          ]);
        } else setFetchError("No event disease was found");

        setLoading(false);
      } catch (error) {
        setFetchError("An error occured while fetching data");
      }
    };

    getDiseaseInfo();
    return () => {
      setCenter([0, 0]);
      setEventReport(eventReportTemplate);
      setFetchError("");
      setLoading(false);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const donwloadAsPdf = async () => {
    const canvas = await html2canvas(document.getElementById("divToPrint"), {
      useCORS: true,
    });
    const imgData = canvas.toDataURL("image/jpeg", 1.0);
    const pdfWidth = Math.trunc((canvas.width * 180) / canvas.height);
    const pdf = pdfWidth > 204 ? new jsPDF("l", "mm", "a4") : new jsPDF();
    pdf.addImage(imgData, "JPEG", 5, 10, pdfWidth, 180);
    pdf.save(`DiseaseEvent-${id}.pdf`);
  };

  return fetchError ? (
    <div className="container mt-3 d-flex justify-content-center">
      <span className="text-danger pt-3">{fetchError}</span>
    </div>
  ) : (
    <EventReportContent
      donwloadAsPdf={donwloadAsPdf}
      mapCenter={mapCenter}
      eventReport={eventReport}
      loading={loading}
    />
  );
};

export default EventReport;
