import { formatDate } from "./utils";

export const fetchDiseaseInfoById = async (id) => {
  try {
    const init = {
      method: "POST",
      body: JSON.stringify({ data: { event_id: id } }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    };
    const resSpecies = await fetch(
      "https://europe-west1-fao-empresi.cloudfunctions.net/getEventSpeciesInfo",
      init
    );
    const resDisease = await fetch(
      "https://europe-west1-fao-empresi.cloudfunctions.net/getEventDiseaseInfo",
      init
    );
    const resLabs = await fetch(
      "https://europe-west1-fao-empresi.cloudfunctions.net/getEventLabInfo",
      init
    );

    const speciesResult = await resSpecies.json();
    const diseaseResult = await resDisease.json();
    const labResult = await resLabs.json();

    const dataSpecies = speciesResult.result;
    const dataDisease = diseaseResult.result;
    const dataLab = labResult.result;
    dataLab.forEach((element) => {
      element.result_date = formatDate(element.result_date);
    });

    let serT = dataDisease.serotypes || "";
    if (serT && serT.charAt(0) === ";" && serT.charAt(serT.length - 1) === ";")
      serT = serT.slice(1, -1);

    const ret = {
      location: {
        lat: dataDisease.latitude || 0,
        lng: dataDisease.longitude || 0,
        region: dataDisease.region || "",
        country: dataDisease.country || "",
        admin: dataDisease.admin1 || "",
        locality: dataDisease.locality || "",
        quality_of_coordinates: dataDisease.quality_of_coordinates || "",
      },
      disease: {
        status: dataDisease.status,
        disease: dataDisease.disease,
        serotypes: serT,
        source: dataDisease.diagnosis_source,
      },
      generalInfo: {
        disease_event_id: dataDisease.disease_event_id,
        reporting_date: formatDate(dataDisease.reporting_date),
        observation_date: formatDate(dataDisease.observation_date),
      },
      species: dataSpecies,
      laboratories: dataLab,
    };

    if (dataDisease.has_humans_affected) {
      ret.humans_cases = {
        humans_affected: dataDisease.humans_affected,
        humans_deaths: dataDisease.humans_deaths,
      };
    }
    return ret;
  } catch (error) {
    console.log("cloud functions failed", error);
    throw error;
  }
};
