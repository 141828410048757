import { MapContainer, TileLayer, Marker } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import iconSvg from "../../images/diseases_dark.svg";

const getIcon = (iconSize) =>
  new L.Icon({
    iconUrl: iconSvg,
    iconRetinaUrl: iconSvg,
    iconSize,
  });

const LeafMap = ({ center, zoom, loading }) => {
  return (
    <div className="map">
      {loading ? (
        <div className="w-100 h-100 skeleton" />
      ) : (
        <MapContainer center={center} zoom={zoom}>
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <Marker icon={getIcon(35)} position={center} />
        </MapContainer>
      )}
    </div>
  );
};

export default LeafMap;
