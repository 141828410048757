export const formatDate = (stringDate) => {
  let date = new Date(stringDate);
  let [month, day, year] = [
    "" + (date.getMonth() + 1),
    "" + date.getDate(),
    date.getFullYear(),
  ];

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [day, month, year].join("/");
};
