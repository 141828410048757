import IframeResizer from "iframe-resizer-react";

const Diseases = () => {
  return (
    <div className="frame-container">
      <IframeResizer
        className="iframe-hand-in-hand frame"
        log
        scrolling="true"
        src="https://empresi-shiny-app-dot-fao-empresi.ew.r.appspot.com/#!/"
        sizeHeight="true"
        sizeWidth="true"
        heightCalculationMethod="max"
      />
    </div>
  );
};

export default Diseases;
