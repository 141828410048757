import IframeResizer from "iframe-resizer-react";

const Home = () => {
  return (
    <div className="frame-container">
      <IframeResizer
        className="iframe-hand-in-hand frame"
        log
        scrolling="true"
        src="https://data.apps.fao.org/empres-i/"
        sizeHeight="true"
        sizeWidth="true"
        heightCalculationMethod="max"
      />
    </div>
  );
};

export default Home;
