import { Routes, Route } from "react-router-dom";
import Home from "./Home";
import Epidemiology from "./Epidemiology";
import Laboratories from "./Laboratories";
import Diseases from "./Diseases";
import Events from "./Events";
import EventReport from "./eventReport/EventReport";

const AppContent = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/diseases" element={<Diseases />} />
      <Route path="/epidemiology" element={<Epidemiology />} />
      <Route path="/laboratories" element={<Laboratories />} />
      <Route path="/event-management" element={<Events />} />
      <Route path="/event-report/:id" element={<EventReport />} />
    </Routes>
  );
};

export default AppContent;
