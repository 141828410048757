import { Link, useResolvedPath, useMatch } from "react-router-dom";

const CustomLink = ({ to, iconLight, iconDark, text }) => {
  let resolved = useResolvedPath(to);
  let match = useMatch({ path: resolved.pathname, end: true });

  return (
    <Link to={to} className={ match ? "nav-link active" : "nav-link"}>
      {iconLight && iconDark && (
        <img
          src={match ? iconDark : iconLight}
          className="tabImage"
          alt={text}
        />
      )}
      {text}
    </Link>
  );
};

export default CustomLink;
